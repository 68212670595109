import React from "react"
import DefaultLayout from "../layouts/default"
import Text from "../components/text/Text"

const active = {
  title: "Datenschutzerklärung"
}


const Datenschutz = () => {
  return (
    <DefaultLayout image="/assets/images/IMG_6978.jpg" active={active}>
        <Text>
          <h1>Datenschutzerklärung</h1>
          <p>Version Oktober 2024</p>

          <p>Hiermit bestätige ich, mich an die folgenden Regeln gemäss Datenschutzgesetz und Datenschutzverordnung zu
            halten.</p>
          <p>Zweck dieser Datenschutzerklärung ist es, Sie als meine Klient*in, über meinen Umgang mit Ihren
            persönlichen und sensiblen Daten, die in meiner Praxis gesammelten werden, zu informieren.</p>
          <p>Es werden nur Daten bearbeitet, die mit der therapeutischen Behandlung im Zusammenhang stehen oder die für
            eine Information über allfällige Angebote relevant sind. Es handelt sich dabei ausschliesslich um Daten, die
            ich mit ihrem Einverständnis aufgenommen habe, die Sie mir haben zukommen lassen oder die in öffentlich
            zugänglichen Verzeichnissen (Telefonbuch etc.) zu finden sind. Dies können allgemeine Daten zu Ihrer Person
            (Name, Anschrift, Telefonnummer usw.), Angaben zu Ihrer Gesundheit/zum Krankheitsverlauf, bereits gestellte
            Diagnosen und gegebenenfalls weitere Daten sein, welche Sie mir aufgrund der Behandlung übermittelt haben
            (Patientendossiers).</p>
          <p>Gesammelt, gespeichert und bearbeitet werden diese Daten ausschliesslich in Ihrem Patienten*innendossier,
            resp. in dem für die Administration notwendigen Dossier, insbesondere in der für die Fakturierung gemäss
            Tarif 590 notwendigen App. In diesen beiden Aufbewahrungsorten sind sämtliche an Ihren Daten vorgenommenen
            Bearbeitungsschritte inklusive der verantwortlichen Personen nachvollziehbar.</p>
          <p>Institutionen (Versicherer etc.) werden Ihre Daten oder Teile davon ausschliesslich mit Ihrem expliziten
            Einverständnis zugänglich gemacht. Als Ausnahmen gelten richterliche Anordnungen oder die Durchsetzung
            berechtigter Ansprüche seitens der Praxis.</p>
          <p>Soweit Ihre Daten physisch gesammelt und bearbeitet werden, werden diese in einem abschliessbaren,
            unbefugten Drittpersonen nicht zugänglichen Raum oder Schrank aufbewahrt. Elektronisch gesammelte Daten
            werden gesichert aufbewahrt (Firewall, Passwort etc.).</p>
          <p>Wo nicht kantonale oder andere gesetzliche Regelungen anders bestimmen, werden Ihre von mir gesammelten
            Daten zwanzig Jahre nach Ihrer letzten Konsultation in unserer Praxis gelöscht.</p>
          <p>Als gegenwärtige oder ehemalige Klient*in können Sie von mir jederzeit ein Doppel aller Ihrer von mir
            gesammelten Daten als Kopie oder in einem gängigen elektronischen Format verlangen.</p>

          <h3>Datenschutz Website</h3>
          <p>Die nachfolgende Datenschutzerklärung gilt für die Nutzung meiner Webseite sowie der darauf angebotenen
            Dienstleistungen. Die vorliegende Datenschutzerklärung informiert Sie über die Erhebung personenbezogener
            Daten während Ihres Besuchs auf dieser Webseite. Mit der Nutzung dieser Webseite erklären Sie sich mit der
            Bearbeitung Ihrer Personendaten gemäss der vorliegenden Datenschutzerklärung einverstanden.</p>
          <p>Die Erhebung und die Verarbeitung Ihrer personenbezogenen Daten geschehen unter Beachtung der geltenden
            datenschutzrechtlichen Gesetze und Anforderungen. Im Übrigen lehnt strichpunkt jegliche Haftung bei der
            Nutzung dieser Webseite ab.</p>

          <h3>Links anderer Webseiten</h3>
          <p>Diese Webseite kann Links zu Webseiten anderer Anbieter enthalten. Strichpunkt hat keinen Einfluss auf
            diese Webseiten, deren Inhalte, Angebote oder Verfügbarkeit oder die dort geltenden Datenschutzerklärungen
            und -bestimmungen. Sie lehnt daher jegliche Haftung ab.</p>

          <h3>Kommunikationsmöglichkeiten</h3>
          <p>Die Webseite von Strichpunkt enthält gewisse Angaben, die eine schnelle elektronische Kontaktaufnahme sowie
            eine unmittelbare Kommunikation ermöglichen, was ebenfalls eine allgemeine E-Mail-Adresse umfasst. Nehmen
            Sie über die auf unserer Webseite angebotenen Kommunikationsmöglichkeiten Kontakt mit uns auf, werden Ihre
            Kontaktangaben und Inhalte lediglich zur Bearbeitung und Beantwortung Ihrer Anfrage verwendet.</p>
          <p>Bitte beachten Sie: Es kann nicht sichergestellt werden, dass Ihre Daten vertraulich übermittelt werden.
            Deshalb sollen bei dieser Kontaktaufnahme keine vertraulichen Daten (medizinische Anliegen etc.) übermittelt
            werden.</p>

          <h3>Ihre Rechte</h3>
          <p>Gestützt auf das Datenschutzgesetz haben Sie verschiedene Rechte wie beispielsweise ein Auskunftsrecht. Zur
            Geltendmachung Ihrer Rechte setzen Sie sich bitte mit mir in Verbindung. Ich stehe Ihnen für weitere
            diesbezügliche Informationen gerne zur Verfügung.</p>

          <h3>Datum</h3>
          <p>St. Gallen, 1. Oktober 2024</p>
        </Text>
    </DefaultLayout>
  )
}

export default Datenschutz